<template>
  <div id="app">
    <HomeTabs msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
import HomeTabs from './components/HomeTabs.vue'

export default {
  name: 'App',
  components: {
    HomeTabs
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
