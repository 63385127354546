import Vue from "vue";
import App from "./App.vue";

// import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
// Vue.use(ElementUI);

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
Vue.use(Antd);

Vue.config.productionTip = false;

import router from "./router/index";

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
