<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
      <div class="logo" v-if="!collapsed" @click="handleHome">
        Li Meng
      </div>
      <div class="logo" v-else>
        LM
      </div>
      <a-menu theme="dark" mode="inline" :default-selected-keys="['1']">
        <!-- <a-menu-item key="1">
          <a-icon type="user" />
          <span>首页</span>
        </a-menu-item>
        <a-menu-item key="2">
          <a-icon type="google" />
          <span>科学上网</span>
        </a-menu-item>
        <a-menu-item key="3">
          <a-icon type="upload" />
          <span>其他</span>
        </a-menu-item> -->
        <a-menu-item :key="item.name" v-for="item in navContent" @click="handleClickNav(item)">
          <a-icon :type="item.icon" />
          <span>{{ item.name }}</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)" />
      </a-layout-header>
      <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }">
        <!-- Content -->
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
export default {
  data() {
    return {
      collapsed: false,
      navContent: [
        { name: "首页", path: "/home", icon: "user" },
        { name: "科学上网", path: "/vpn", icon: "google" },
        { name: "法律知识", path: "/law", icon: "insurance" },
        { name: "其他", path: "/other", icon: "meh" },
      ]
    };
  },
  created() {

  },
  mounted() {

  },
  methods: {
    handleHome() {
      this.$router.push("/")
    },
    handleClickNav(data) {
      console.log(data)
      this.$router.push(data.path)
    }
  }
};
</script>
<style>
.ant-layout {
  height: 100vh;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  line-height: 32px;
  color: #fff;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  cursor: pointer;
}
</style>
