import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// export default new Router({
//   routes: [
//     {
//       path: "/",
//       name: "HelloWorld",
//       component: HelloWorld,
//     },
// {
//   path: "/",
//   name: "VpnPage",
//   component: () => import("@/components/pages/VpnPage.vue"),
// },
//   ],
// });

const routes = [
  {
    path: "/",
    name: "LiMeng",
    component: () => import("@/components/pages/LiMeng"),
  },
  {
    path: "/home",
    name: "HomePage",
    component: () => import("@/components/pages/HomePage"),
  },
  {
    path: "/vpn",
    name: "VpnPage",
    component: () => import("@/components/pages/VpnPage"),
  },
  {
    path: "/law",
    name: "LawPage",
    component: () => import("@/components/pages/LawPage"),
  },
  {
    path: "/other",
    name: "OtherPage",
    component: () => import("@/components/pages/OtherPage"),
  },
];

const router = new Router({
  routes,
});

export default router;
